import React, { memo, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_BY_ADMIN,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_ENQUIRE,
  TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR,
  TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED,
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
} from '../../util/transaction';
import { createResourceLocatorString } from '../../util/routes';
import { txLastTransition } from '../../util/transactionLongTermFirst';
import routeConfiguration from '../../routeConfiguration';
import css from './TripPanel.css';
import editIcon from '../../assets/tripPage/edit-regular.svg';
import addOnsIcon from '../../assets/tripPage/addons.svg';
import dollarIcon from '../../assets/tripPage/trip-cost.svg';
import contractIcon from '../../assets/tripPage/policies.svg';
import helpIcon from '../../assets/tripPage/help.svg';
import beginnersIcon from '../../assets/tripPage/beginner.svg';
import cancelIcon from '../../assets/tripPage/cancel-trip.svg';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const ImportantLinksSection = ({
  history,
  isCustomer,
  isAccepted,
  onOpenCancelModal,
  onOpenCancelHelpModal,
  isShowCancelLinkCustomer,
  isShowAddonsLinkCustomer,
  transactionId,
  transaction,
  onEditTrip,
  onPoliciesClick,
}) => {

  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const apiResponse = await getEventsByBucket('trip');
        setEventData(apiResponse.data);
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    fetchEventData();
  }, []);


  const showCancelLinkForProvider = isAccepted || txIsAwaitUpdateBookingBeforePickUp(transaction)
  const transactionCreatedAt = transaction.attributes.createdAt;
  const dateForTransactionFromOldTransactionProcess = new Date('December 1, 2021 01:00:00');
  const lastTransition = txLastTransition(transaction);
  const tripWasAccepted = transaction && transaction.attributes && transaction.attributes.transitions.map(t => t.transition === TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE || t.transition === TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT || t.transition === TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED || t.transition === TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR || t.transition === TRANSITION_ACCEPT || t.transition === TRANSITION_ACCEPT_BY_ADMIN || t.transition === TRANSITION_CONFIRM_PAYMENT_INSTANT).includes(true);

  const listOfLinks = isCustomer
    ? [
      {
        id: 1,
        img: addOnsIcon,
        title: <FormattedMessage id="TransactionPanel.addOnsLink" />,
        path: 'AddOnsPage',
        show: isShowAddonsLinkCustomer,
        redirect: true,
      },
      {
        id: 2,
        img: editIcon,
        title: <FormattedMessage id="TransactionPanel.editLink" />,
        path: 'EditTripPage',
        show: tripWasAccepted && !txIsAwaitUpdateBookingBeforePickUp(transaction) && !txIsAwaitUpdateBookingBeforeDropOff(transaction) && transactionCreatedAt >= dateForTransactionFromOldTransactionProcess && !(lastTransition === TRANSITION_ENQUIRE),
        redirect: true,
      },
      {
        id: 3,
        img: dollarIcon,
        title: <FormattedMessage id="TransactionPanel.tripCostLink" />,
        path: 'TripDetailsPage',
        show: lastTransition !== TRANSITION_ENQUIRE,
        redirect: true,
      },
      {
        id: 4,
        img: contractIcon,
        title: <FormattedMessage id="TransactionPanel.policiesLink" />,
        path: 'PoliciesPage',
        show: lastTransition !== TRANSITION_ENQUIRE,
        redirect: true,
      },
      {
        id: 5,
        img: helpIcon,
        title: <FormattedMessage id="TransactionPanel.helpLink" />,
        path: '/',
        isModal: true,
        isCancel: false,
        show: true,
      },
      {
        id: 6,
        img: beginnersIcon,
        title: <FormattedMessage id="TransactionPanel.beginLink" />,
        path: 'BeginnerResourcesPage',
        show: lastTransition !== TRANSITION_ENQUIRE,
        redirect: true,
      },
      {
        id: 7,
        img: cancelIcon,
        title: <FormattedMessage id="TransactionPanel.cancelTrip" />,
        path: '/',
        isCancel: true,
        show: lastTransition === TRANSITION_ENQUIRE ? false : isShowCancelLinkCustomer,
      },
    ]
    : [
      // {
      //   id: 1,
      //   img: dollarIcon,
      //   title: <FormattedMessage id="TransactionPanel.tripDetailsLink" />,
      //   path: 'TripDetailsPage',
      //   show: true,
      //   redirect: true,
      // },
      {
        id: 2,
        img: contractIcon,
        title: <FormattedMessage id="TransactionPanel.policiesLink" />,
        path: 'PoliciesPage',
        show: lastTransition !== TRANSITION_ENQUIRE,
        redirect: true,
      },
      {
        id: 3,
        img: helpIcon,
        title: <FormattedMessage id="TransactionPanel.helpLink" />,
        path: '#',
        isModal: true,
        isCancel: false,
        show: true,
      },
      {
        id: 4,
        img: beginnersIcon,
        title: <FormattedMessage id="TransactionPanel.beginLink" />,
        path: 'BeginnerResourcesPage',
        show: lastTransition !== TRANSITION_ENQUIRE,
        redirect: true,
      },
      {
        id: 5,
        img: cancelIcon,
        title: <FormattedMessage id="TransactionPanel.cancelTrip" />,
        path: '#',
        isCancel: true,
        show: lastTransition === TRANSITION_ENQUIRE ? false : showCancelLinkForProvider,
      },
    ];

  const clickLink = async (path, redirect, id, extraParams) => {

    // console.log("Host event is triggered", id, isCustomer);

    if(isCustomer) {

      if (id === 1) {
    
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.TRIP_VIEW_ADD_ONS_GUEST,
          eventData,
          props: {
            host: transaction.provider,
            guest: transaction.customer,
            trip: transaction,
            ui: {
              button: "Addons",
              page: "TripScreen"
            }
          },
          userId: transaction.customer ? transaction.customer.id.uuid : null,
        });
      }
  
      if (id === 5) {
    
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.TRIP_VIEW_FAQ_GUEST,
          eventData,
          props: {
            host: transaction.provider,
            guest: transaction.customer,
            trip: transaction,
            ui: {
              button: "FAQ",
              page: "TripScreen"
            }
          },
          userId: transaction.customer ? transaction.customer.id.uuid : null,
        });
      }

      if (id === 2) {
    
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.TRIP_VIEW_MODIFICATION_PAGE_GUEST,
          eventData,
          props: {
            host: transaction.provider,
            guest: transaction.customer,
            trip: transaction,
            ui: {
              button: "Edit Trip",
              page: "TripScreen"
            }
          },
          userId: transaction.customer ? transaction.customer.id.uuid : null,
        });
      }
  
      if (id === 3) {
    
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.TRIP_VIEW_PRICE_BREAKDOWN_GUEST,
          eventData,
          props: {
            host: transaction.provider,
            guest: transaction.customer,
            trip: transaction,
            ui: {
              button: "Trip Cost Breakdown",
              page: "TripScreen"
            }
          },
          userId: transaction.customer ? transaction.customer.id.uuid : null,
        });
      }
  
      if (id === 4) {
    
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.TRIP_VIEW_IMPORTANT_DOCUMENTS_GUEST,
          eventData,
          props: {
            host: transaction.provider,
            guest: transaction.customer,
            trip: transaction,
            ui: {
              button: "View Documents",
              page: "TripScreen"
            }
          },
          userId: transaction.customer ? transaction.customer.id.uuid : null,
        });
      }
  
      if (id === 7) {
    
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.TRIP_VIEW_CANCEL_GUEST,
          eventData,
          props: {
            host: transaction.provider,
            guest: transaction.customer,
            trip: transaction,
            ui: {
              button: "Cancel Trip",
              page: "TripScreen"
            }
          },
          userId: transaction.customer ? transaction.customer.id.uuid : null,
        });
      }
    }
    else {
      // console.log("Host event is triggered", id);
      // Host Events here
      if (id === 5) {
    
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.TRIP_VIEW_CANCEL_HOST,
          eventData,
          props: {
            host: transaction.provider,
            guest: transaction.customer,
            trip: transaction,
            ui: {
              button: 'Cancel Trip',
              page: 'TripDetailsScreen'
            }
          },
          userId: transaction.provider ? transaction.provider.id.uuid : null,
        });
      }
      if (id === 4) {
    
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.TRIP_VIEW_IMPORTANT_DOCUMENTS_HOST,
          eventData,
          props: {
            guest: transaction.customer,
            trip: transaction,
            host: transaction.provider,
            ui: {
              button: "Beginner resources",
              page: 'TripDetailsScreen'
            }
          },
          userId: transaction.provider ? transaction.provider.id.uuid : null,
        });
      }

      if (id === 3) {
    
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.TRIP_VIEW_HELP_TAB_HOST,
          eventData,
          props: {
            guest: transaction.customer,
            trip: transaction,
            host: transaction.provider,
            ui: {
              button: "Need help? Contact us",
              page: 'TripDetailsScreen'
            }
          },
          userId: transaction.provider ? transaction.provider.id.uuid : null,
        });
      }

      // if (id === 3) {
      // 
      //   triggerAnalyticsEvent({
      //     event_id: event_trigger_ids.TRIP_VIEW_PRICE_BREAKDOWN_HOST,
      //     eventData,
      //     props: {
      //       guest: transaction.customer,
      //     },
      //     userId: transaction.customer ? transaction.customer.id.uuid : null,
      //   });
      // }

      // if (id === 5) {
      // 
      //   triggerAnalyticsEvent({
      //     event_id: event_trigger_ids.TRIP_VIEW_FAQ_HOST,
      //     eventData,
      //     props: {
      //       guest: transaction.customer,
      //     },
      //     userId: transaction.customer ? transaction.customer.id.uuid : null,
      //   });
      // }
  
  

    }

   
    if(extraParams) {
      return ;
    }

    const routes = routeConfiguration();
    history.push(createResourceLocatorString(path, routes, { id: transactionId }, {}), { startDate: transaction.booking.attributes.start, endDate: transaction.booking.attributes.end });
  };

  return (
    <div className={css.importantLinksContainer}>
      <h3 className={css.importantLinksTitle}>
        <FormattedMessage id="TransactionPanel.importantLinksTitle" />
      </h3>
      {listOfLinks.map(link => {
        return link.show ? (
          <div
            key={link.id}
            className={css.linkContainer}
            onClick={() => {
              if (link.isModal) {
                onOpenCancelHelpModal();
                clickLink(link.path, link.redirect, link.id, 'extraParamForModal');
              } else if (link.isCancel) {
                onOpenCancelModal();
                clickLink(link.path, link.redirect, link.id, 'extraParamForCancel');
              } else if (link.edit) {
                onEditTrip();
                clickLink(link.path, link.redirect, link.id, 'extraParamForEdit');
              } else if (link.policies) {
                onPoliciesClick();
                clickLink(link.path, link.redirect, link.id, 'extraParamForPolicies');
              } else {
                clickLink(link.path, link.redirect, link.id);
              }
            }}
          >
            <div className={css.imgTextContainer}>
              <div className={css.imgLinkContainer}>
                <img src={link.img} alt="link Icon" className={css.imgLink} />
              </div>
              <p className={css.linkTitle}>{link.title}</p>
            </div>
            <div className={css.arrowLink}></div>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default ImportantLinksSection;
