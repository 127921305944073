// import { loginAmplitude, logoutAmplitude } from './amplitude';

export const loginIntercomUser = (currentUser, app_id) => {
  if (typeof window.Intercom === 'undefined') {
    
    return;
  }

  if (!currentUser || !currentUser.id) {
    return;
  }

  const profile = currentUser.attributes.profile;

  const intercomSettings = window.intercomSettings || {
    app_id,
  };

  intercomSettings.user_id = currentUser.id.uuid;
  intercomSettings.email = currentUser.attributes.email;
  intercomSettings.phone = profile.protectedData.phoneNumber;
  intercomSettings.name = `${profile.firstName} ${profile.lastName}`;

  window.Intercom('shutdown');
  window.Intercom('boot', intercomSettings);

  // loginAmplitude({
  //   userId: `${currentUser.id.uuid} - ${intercomSettings.email}`,
  //   userData: {
  //     Email: intercomSettings.email,
  //     Phone: intercomSettings.phone,
  //     Name: intercomSettings.name,
  //   }
  // });

  if (typeof window.localStorage !== 'undefined') {
    window.localStorage.setItem('uvk', currentUser.attributes.profile.privateData.userVerificationKey);
  }
};

export const logoutIntercomUser = (app_id) => {
  if (typeof window.Intercom === 'undefined') {
    return;
  }

  const intercomSettings = {
    app_id,
    user_id: null,
    email: null,
    phone: null,
    name: null,
    role: null,
  };

  window.Intercom('shutdown');
  window.Intercom('boot', intercomSettings);

  if (typeof window.localStorage !== 'undefined') {
    window.localStorage.removeItem('uvk');
  }

  // logoutAmplitude();
};
